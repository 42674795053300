body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.config_slider {
  margin: 10px 0px !important;
  margin-top: 5px !important;
  .ant-slider-rail {
    background-color: #cbe4ff !important;
  }
  .ant-slider-track {
    background-color: #56bfff !important;
  }
}